@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?z2q8l');
    src:  url('fonts/icomoon.eot?z2q8l#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?z2q8l') format('truetype'),
      url('fonts/icomoon.woff?z2q8l') format('woff'),
      url('fonts/icomoon.svg?z2q8l#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-down:before {
    content: "\e904";
  }
  .icon-chevron-right:before {
    content: "\e905";
  }
  .icon-chevron-down:before {
    content: "\e906";
  }
  .icon-phone-outline:before {
    content: "\e902";
  }
  .icon-instagram:before {
    content: "\e900";
  }
  .icon-phone:before {
    content: "\e901";
  }
  .icon-send:before {
    content: "\e903";
  }
  