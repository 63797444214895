.offer-locations {
  position: relative;
  top: -6px;
  z-index: 3;
}

.offer-header {
    align-items: start;
    display: flex;
    justify-content: flex-start;

    &.end {
        justify-content: flex-end;
    }
}

ul.question-mark,
ul.tilde {
    list-style-type: '';

    li {
        font-weight: bolder;
        margin-bottom: 12px;
        list-style: none;
        
        span {
            position: relative;
            font-family: $non-serif-font;
        }

        > span {
          padding-left: 0;
         
          &::before {
              color: #d99f91;
              display: block;
              position: absolute;
              height: 100%;
              width: auto;
              content: '';
              z-index: 0;
              left: -1rem;
              top: -6px;
              transform: translateX(calc(-100% - 12px));          
              font-family: $serif-font;
          }
        }
    }
}

ul.question-mark li span::before {
  content: '? ';
}

ul.tilde li span::before {
  content: '~ ';
}

.text-dark {
  color: #777 !important;
}

.line-decoration-right,
.line-decoration-left {
  position: relative;
}

.offer-details {
  .btn {
    z-index: 1;
    position: relative;
  }

  ul {
    display: inline-grid;
  }
}

.line-decoration-right::before,
.line-decoration-left::before {
  display: block;
  position: absolute;
  height: 1px;
  width: 75vw;
  max-width: 50%;
  background: #d99f91;
  content: '';
  z-index: 0;
  // right: 24px;
  left: -33%;
  top: 50%;
  pointer-events: none;
}

.line-decoration-left::before {
  right: unset;
  // left: 24px;
  left: 90%;
}

.offer-main-graphic {
  position: relative;
  width: calc(100% - 70px);
  height: calc(100% + 100px);
  top: -100px;
  object-fit: cover;
  border-top-left-radius: 150px;
  max-height: 710px;
}

.offer-main-graphic.small {
  top: 0;
  height: 350px;
  object-fit: cover;
  object-position: 0 -60px;
  margin-bottom: -28px;
  margin-top: 20px;
}

.offer-arrow {
  position: absolute;
  top: 60px;
  right: 0;
  transform: rotate(180deg);
}

@media (max-width: 991.99px) {
  .line-decoration-left::before,
  .line-decoration-right::before {
    display: none;
  }

  .with-line::before {
    display: block;
    position: absolute;
    height: 1px;
    width: 100vw;
    background: #d99f91;
    content: '';
    z-index: 0;
    left: calc(100% + 24px);
    top: 24px;
  }

  .offer-main-graphic {
    border-radius: 12px;
    border-top-right-radius: 150px;
    max-width: 450px;
    &.small {
      object-position: 0 0;
    }
  }
}