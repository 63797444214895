.react-parallax-bgimage {
    min-width: 100vw;
}
  
.react-parallax {
    // height: 100%;
    height: calc(100vh - 100px);
    min-height: 700px;
    z-index: 2;

    .react-parallax-background-children {
        min-height: 100%;

        .react-parallax-background {
            min-height: calc(100vh - 100px);
            width: 100%;

            img {
                border-radius: 0;
                min-height: calc(100vh - 100px);
                min-width: 100vw;
                max-height: calc(100vh - 100px);
                max-width: 2048px;
                object-fit: cover;
                min-height: 700px;
            }
        }
    }

    .react-parallax-content {
        top: 300px;
        transform: translateY(calc(-100% + 185px));

        & > .container {
            top: -85px;
            position: relative;
        }
    }
}

@media (min-width: 768px) {
    .react-parallax-background.custom-bg {
        object-position: right;
    }
}
