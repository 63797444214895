footer {
  padding: 100px 0;

  .contact-options i {
    opacity: 0.3;
  }

  .instagram-buttons-container {
    align-content: end;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}