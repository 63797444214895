body {
  font-family: $serif-font;
  scroll-behavior: smooth;
}

p, ul.nav li, .non-serif, .form-control {
  font-family: $non-serif-font;
}

.serif {
  font-family: $serif-font;
}

main {
  min-height: 100px;
}

img {
  border-radius: 8px;
}

.z-index-0 {
  z-index: 0;
}

.btn {
  border-radius: 30px;
  z-index: 1;
  position: relative;
}

h2 {
    position: relative;
    z-index: 1;
}

.grecaptcha-badge { 
  visibility: hidden;
}

.navbar-toggler:not(.collapsed) {
  .navbar-toggler-icon {
    // remember to replace "#" with "%23"!
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 378.303 378.303' style='enable-background:new 0 0 378.303 378.303;' xml:space='preserve'><polygon fill='%23FFFFFF;' points='378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018   28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 '/><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g><g> </g> </svg>");
    } 
}

.btn.square-border-nw {
  border-top-left-radius: 5px;
}

.btn.square-border-ne {
  border-top-right-radius: 5px;
  
}

.btn.square-border-sw {
  border-bottom-left-radius: 5px;
}

.btn.square-border-se {
  border-bottom-right-radius: 5px;
}

.shadow {
  box-shadow: 0px 4px 21px 1px #7b5151b8;
}

.icon-phone-outline::before {
  display: inline-block;
  transform: rotate(90deg);
}

.btn {
  [class^="icon-"], [class*=" icon-"] {
    margin-right: 8px;
  }
}

.bg-tertiary {
  background-color: #f0c3b2;
}

.bg-quaternary {
  background-color: #fdedee;
}

.btn.bg-quaternary {
  background-color: #ebc6bf;
  border-color: #ebc6bf;
}

.btn small {
  font-size: 0.6em;
}

.rotate-180 {
  transform: rotate(180deg);
}

.was-validated input.form-control:-webkit-autofill {
  padding: 0.375rem 0.75rem;
}

.invalid-feedback {
  font-family: $non-serif-font;
}

.loader-container.body, .loader-container.box {
  z-index: 2;
}

.loader-container.body {
  background-color: #d99f91 !important;
  top: 100px !important;
  max-height: calc(100vh - 100px);

  .loader.body {
    background-color: rgba(0,0,0,0);
  }

  .spinner {
    border-color: #f0c3b2;
    border-top-color: #fdedee;
  }
}

.fading-loader {
  height: calc(100vh - 100px);
  opacity: 1;
  transition: opacity 0.3s;
  position: fixed;
  top: 100px !important;
  width: 100%;
  z-index: 3;
  pointer-events: none;

  &.fading {
    opacity: 0;
  }
}

.navbar-toggler {
  box-shadow: none;
  border: none;
  filter: invert(1);

  &:focus, &:not(.collapsed) {
    border: none;
    // box-shadow: black 0 0 6px -2px;
    box-shadow: none;
  }
}

.nav-link {
  font-size: 14px;
}

.navbar {
  min-height: 100px;

  ul li {
    align-items: center;
    display: flex;

    a {
      margin: auto
    }
  }
}

.nav-link.active, .nav-link:hover {
  &.text-secondary {
    color: #F0C3B2 !important;
  }
}

.lead {
  font-size: 40px;
  line-height: 57px;
  width: 320px;
}

.move-on-hover {
  position: relative;
  transition: top 0.6s ease-out;
  top: 0;
}

.react-parallax,
.intro {
  &:hover .move-on-hover {
    top: -30px;
  }
}

body .text-dark {
  color: #585858 !important;
}

p {
  line-height: 26px;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1rem;
}

.btn-lg.smaller, nav .btn-lg {
  font-size: 1rem;
}

.navbar-brand {
  margin-right: 6px;
}

.nav-tel-btn {
  font-size: 20px;
  -webkit-appearance: initial;
}

a[type="button"] {
  -webkit-appearance: initial;
}

.logo {
  height: 50px;
  width: 50px;
}

.nav-instagram-links .btn {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.cursor-normal {
  cursor: default !important;
}

a, a:visited { outline: none; }

.poster img {
  border-radius: 0;
}

ul li {
  vertical-align: top;
}

@media (min-width: 399px) {
  .navbar-toggler-icon {
    height: 50px;
    width: 50px;
    top: -8px;
    position: relative;
  }

  .navbar-brand {
    margin-right: 1rem;
  }

  .nav-tel-btn {
    font-size: 1rem;
  }
  
  .lead {
    font-size: 50px;
  }

  .logo {
    height: 70px;
    width: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  .nav-instagram-links,
  .nav-tel-btn {
    font-size: 1rem;
  }
}