.contact-form {
    position: relative;
    z-index: 3;

    input, textarea {
        font-size: 18px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #d99f91;
        opacity: 1; /* Firefox */
        font-weight: initial;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #d99f91;
        font-weight: initial;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #d99f91;
        font-weight: initial;
    }

    input, textarea {
        font-weight: bolder;
        border-color: rgba(0,0,0,0);
    }

    .contact-form-graphic {
        position: relative;
        width: calc(100% - 70px);
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 150px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}