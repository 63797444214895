.about-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    pointer-events: none;
    z-index: 2;
    position: relative;
}

.quote-image {
    position: absolute;
    top: -20px;
    left: 30px;
    pointer-events: none;
}


@media (max-width: 767.99px) {
    .quote-image {
        left: 80px;
    }
}