.realization-image {
  max-width: 100%;
  aspect-ratio: 1 / 1;
}

.realization-slide {
    padding: 12px;
    position: relative;
}

.poster, .realizations-video {
    object-fit: cover;
    width: 100%;
    min-width: 100%;
    // height: '100%';
    aspect-ratio: 16 / 9;
    display: block;
    z-index: 2;
    position: relative;
}

.poster img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}