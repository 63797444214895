.slick-list::after {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
    pointer-events: none;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    top: 0;
}