.phones-graphic {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 3;
    max-height: calc(300%);
}

.shape-1 {
    position: absolute;
    top: -150px;
    left: -33vw;
    z-index: 1;
    pointer-events: none;
}

.shape-2 {
    position: absolute;
    top: 50%;
    right: -33vw;
    z-index: 1;
    pointer-events: none;
    transform: translateY(-50%);
}

.shape-3 {
    position: absolute;
    top: 0px;
    left: -33vw;
    z-index: 1;
    pointer-events: none;
}

.blub-1 {
    position: absolute;
    top: 50%;
    right: -33vw;
    z-index: -1;
    pointer-events: none;
    transform: translateY(-50%);
}

.blub-2 {
    position: absolute;
    top: 66%;
    right: -25vw;
    z-index: 0;
    pointer-events: none;
    transform: translateY(-50%);
}

@media (max-width: 767.99px) {
    .blub-2 {
        top: 39%;
    }
}

@media (min-width: 768px) {
    .phones-graphic {
        position: absolute;
        top: -150px;
        left: 0;
    }
}