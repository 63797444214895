.offer-button-container {
  a {
    text-decoration: none;
  }
}

.offer-button {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;

  // * {
  //   pointer-events: none;
  //   z-index: 0;
  // }
  

  &.primary {
    .chevron-down {
      display: none;
    }
  }

  .offer-title,
  .icon-chevron-down,
  .icon-chevron-right  {
    z-index: 1;
    position: relative;
  }

  .offer-title {
    padding-top: 24px;
    padding-left: 24px;
    min-height: 92px;
    width: 0;
  }

  .background {
    border-radius: 6px;
    display: block;
    position: absolute;
    height: calc(100% + 50px);
    width: calc(66% + 60px);
    background: #d99f91;
    z-index: 0;
    top: 0;
  }

  &.secondary {
    .chevron-right {
      display: none;
    }
    
    .background {
      background: #fff;
      aspect-ratio: 1 / 1;
      height: unset;
    }
  }

  img {
    display: block;
    max-width: calc(100% - 50px);
    position: relative;
    left: 50px;
  }

  .chevron-right,
  .chevron-down {
    height: 42px;
    left: unset;
  }
  
  .chevron-down {
    top: -50px;
    transform: rotate(90deg);
    filter: invert(1) brightness(1.5);
  }

  .chevron-right {
    float: right;
    top: unset;
    bottom: -25px;
  }

  img.offer-image {
    margin-top: 10px;
    width: calc(100% - 50px);
    height: auto;
    object-fit: cover;
    aspect-ratio: 24 / 25;
    object-position: top;
    border-top-left-radius: 150px;
  }
}

@media (max-width: 1199.99px) {
  .offer-button {
    img.offer-image {
      width: calc(100% + 20px);
      max-width: calc(100% + 20px);
    }
  }
}

@media (max-width: 767.99px) {
  .offer-button-container {
    max-height: 150px;

    .offer-button {
      position: relative;

      &.secondary {
        .chevron-right {
          display: initial;
          filter: invert(1) sepia(1) hue-rotate(336deg);
        }
      }
  
      .background {
        height: 100%;
        top: 50px;
        width: 100%;
      }
  
      .offer-title {
        top: 35px;
      }
      
      .chevron-down {
        display: none;
      }
  
      .chevron-right {
        bottom: unset;
        z-index: 1;
        position: relative;
        transform: translateY(-50%);
        right: 30%;
        top: 0;
        filter: invert(1) brightness(1.5);
  
        &::before {
          color: #fff;
        }
      }

      img.offer-image {
        border-radius: 0;
        border-top-left-radius: 100px;
      }
      
      img {
        bottom: -50px;
        width: 35%;
        position: absolute;
        right: 0;
        left: unset;

        &.offer-image {
          top: -30px;
          min-height: 230px;
        }
      }
    }
  }

  .offer-button-container:nth-child(odd) {
    .offer-button {
      img.offer-image {
        right: unset;
        left: 0;
        border-radius: 0;
        border-top-right-radius: 100px;
      }

      .offer-title {
        left: 35%;
      }

      .chevron-right  {
        left: 30px;
      }
    }
  }
}

@media (max-width: 460px) {
  .offer-button-container {
    .offer-button {
      img.offer-image {
        border-top-left-radius: 80px;
        width: 40%;
      }
  
      .offer-title {
        padding-left: 18px;
      }
    }
  }
  
  .offer-button-container:nth-child(odd) {
    .offer-button {
      img.offer-image {
        border-radius: 0;
        border-top-right-radius: 80px;
      }

      .offer-title {
        left: 40%;
        padding-left: 6px;
      }
    }
  }
}